<template>
 <div class="goodDetail">
   <div v-if="isData">
      <van-nav-bar>
          <template #title>
              <p class="vocation_name hidden-single">{{detailObj.name}}</p>
          </template>
          <template #left>
              <span @click="back">
                  <svg-icon icon-class="back-icon" class-name="icon" ></svg-icon>
              </span>
              <span @click="backHome">
            <svg-icon icon-class="icon-home" class-name="icon icon-home" ></svg-icon>
              </span>
          </template>
      </van-nav-bar>
      <div class="detail-head">
        <div class="bg">
          <!-- <van-icon name="arrow-left" @click="back"/> -->
          <img class="courseImg" :src="detailObj.picUrl"/>
          <!-- <span v-if="detailObj.taskType" class="cType">{{type}}</span> -->
        </div>
          <div class="detail-box">
                <div class="detail-title">
                    {{detailObj.name}}
                    <img src="../../../assets/images/courseGoods/cert-icon.png" v-if="detailObj && detailObj.hasCert" class="icon" alt="">
                </div>
                <!-- <div class="label">
                    <span class="label1">养老护理员</span>
                    <span class="label2">养老护理员</span>
                    <span class="label3">高级</span>
                </div> -->
                <ul class="tag-list-detail">
                    <li class="tag-item tag-active "
                    v-for="(tag, index) in detailObj.labelNameList"
                    :key="index"
                    >
                        <div class="text-tag">{{tag}}</div>
                    </li>
                </ul>
                <!-- 课时 视频数 -->
                <ul class="video-course">
                  <li class="item" v-show="detailObj.videoNum"><span class="num">{{detailObj.videoNum}}</span><span class="text">视频</span></li> 
                  <li class="bar" v-show="detailObj.videoNum && detailObj.studyHoursNum"></li>
                  <li class="item" v-show="detailObj.studyHoursNum"><span class="num">{{detailObj.studyHoursNum}}</span><span class="text">课时</span></li> 
                </ul>
                <!-- 报名周期 -->
                <!-- 培训周期 -->
               
                <div class="zhouqi"> 
                  <p class="sign-time peixun" v-if="detailObj.trainingStartTime && detailObj.trainingEndTime">
                    <span class="label-zhouqi">培训周期：</span>
                    <span class="time-detail">{{formatDate(detailObj.trainingStartTime)}} - {{formatDate(detailObj.trainingEndTime)}}</span>
                  </p>
                  <p class="sign-time peixun" v-else-if="detailObj.cycleType === 3">
                    <span class="label-zhouqi">培训周期：</span>
                    <span class="time-detail">长期有效</span>
                  </p>
                  <p class="sign-time peixun" v-else-if="detailObj.cycleType === 2">
                    <span class="label-zhouqi">培训周期：</span>
                    <span class="time-detail">随到随学</span>
                  </p>
                </div>
                 <div class="zhouqi"> 
                  <p class="sign-time" v-show="detailObj.signUpStartTime && detailObj.signUpEndTime">
                    <span class="label-zhouqi">报名周期：</span>
                    <span class="time-detail">{{formatDate(detailObj.signUpStartTime)}} - {{formatDate(detailObj.signUpEndTime)}}</span>
                  </p>
                  
                </div>
                <!-- 职业工种等 -->
                <ul class="zhiye-list" v-if="detailObj.profession || detailObj.workType || (detailObj.workType && detailObj.skillLevel)">
                  <li class="zhiye-item" v-if="detailObj.profession">
                    <span class="icon"></span>
                    <span class="label-zhiye">所属职业：</span>
                    <span class="label-text">{{detailObj.profession}}</span>
                  </li>
                  <li class="zhiye-item" v-if="detailObj.workType && detailObj.skillLevel">
                    <span class="icon"></span>
                    <span class="label-zhiye">技能等级：</span>
                    <span class="label-text">{{detailObj.skillLevel}}</span>
                  </li>
                  <li class="zhiye-item" v-if="detailObj.workType">
                    <span class="icon"></span>
                    <span class="label-zhiye" >所属工种：</span>
                    <span class="label-text">{{detailObj.workType}}</span>
                  </li>
                </ul>
                <!-- 素材供应商 -->
                <div class="sucai-provider" v-if="detailObj.resourceProviderLogo"> 
                  <img class="icon" :src="detailObj.resourceProviderLogo"/>
                  <!-- <span class="provider-name">不知道哪家公司</span> -->
                </div>
                <!-- <div class="price-box" v-if="!detailObj.hidePriceTag">
                    <span class="price">{{!detailObj.isFree?'￥'+detailObj.salePrice:'免费'}}</span><span class="or-price" v-if="!detailObj.isFree&&detailObj.basePrice">￥{{detailObj.basePrice}}</span>
                </div>
                <div class="detail-price">
                    <span class="detail-lasttime"><span class="detail-lasttime label-color" >有效期：</span>{{time}}</span>
                    <span v-if="isJoinPer=='true'" class="persons">{{detailObj.purchaseNo}}人已报名</span>
                </div> -->
          </div>
      </div>
      <div class="detail-content">
          <van-tabs v-model="active" @click="onChange">
              <van-tab title="简介">
                <!-- <img class="courseImg" :src="detailObj.imgUrl"/> -->
                <div v-html="detailObj.desc" class="introCode"></div>
                </van-tab>
              <van-tab title="目录" v-if="isTab=='true'&&detailObj.goodsType!=3">
                  <div class="list">
                      <courseItem :itemList="catalogList" :num="0" :isFirst="isFirst" :goodsId="goodsId" :sort="1" :isOverType="isOverType" :detailObj="detailObj" :isRenshe="isRenshe"></courseItem>
                  </div>
              </van-tab>
          </van-tabs>
      </div>
      <div class="myTorst" v-show="showT">
        <img src="../../../assets/images/courseGoods/bingo.png" class="bingo"/>
        <div>已成功加入</div>
      </div>
      <div class="detail-foot">
          <!-- 人社平台 -->
        <div class="renshe" v-if="isRenshe">
          <div class="renshe-footer-wraper" v-if="isLogin && !wdSubBranch">
            <div class="price-left">
              <p class="price-yuji">
                <!-- <span class="price-label">预计收益：</span>
                <span class="yuji-price-1">¥</span>
                <span class="yuji-price">{{detailObj.preIncome}}</span> -->
              </p>
              <p class="price-sale">
                <!-- <span v-if="detailObj.isFree" class="price-label">免费</span>
                <span v-else>
                  <span class="price-label">商品售价</span>
                  <span class="yuji-price">¥{{detailObj.salePrice}}</span>
                </span> -->
              </p>
            </div>
            <div class="right-btn"
              v-if="!wdSubBranch"
              :class="detailObj.isDistribute?'right-btn-active':''"
              @click="rensheLoginTuiguang"
            >
              {{!detailObj.isDistribute?'推广':'已推广'}}
            </div>
          </div>
          <div class="renshe-nologin" @click="rensheWithoutLogin" v-else-if="detailObj.resourceProviderFlag"> 
            立即报名
          </div>
        </div>
        <div v-else>
          <!-- <button @click="enroll" v-if="isShowNowSignUp && detailObj.status==1&&!detailObj.isOrder">立即报名</button> -->
          <div class="renshe" v-if="isShowNowSignUp && detailObj.status==1&&!detailObj.isOrder">
             <div class="renshe-footer-wraper"  > 
              <div class="price-left">
                <p class="price-yuji" v-if="detailObj.isFree && $store.state.app.isWdBranch===true">
                  <!-- <span class="yuji-price">免费</span>  -->
                </p>
                <p class="price-yuji" v-if="(!detailObj.isFree) && $store.state.app.isWdBranch===true">
                  <!-- <span class="price-label"></span>
                  <span class="yuji-price-1">¥</span>
                  <span class="yuji-price">{{detailObj.salePrice}}</span> -->
                </p>
                <p class="price-sale">
                  <!-- <span class="price-label">商品售价</span> -->
                  <span class="yuji-price">{{detailObj.purchaseNo}}人已报名</span>
                </p>
              </div>
                
              <!--  -->
              <div class="right-btn" 
                :class="isGuoqi()?'right-btn-active':''"
                @click="enroll(1)"
              >
                立即报名
              </div>
            </div>
          </div>
         
          <div v-else-if="detailObj.status==1&&detailObj.isOrder">
            <!-- 测评 -->
            <template v-if="detailObj.goodsType == 3">
              <button class="rightBtn" @click="isToStudy" :class="isOverType=='oks'?'':'downClass'">{{isOver}}</button>
            </template>
            <button @click="isToStudy" :class="isOverType=='oks'?'':'downClass'">{{isOver}}</button>
            <!-- </template> -->
          </div>
          <button v-else-if="isShowNowSignUp" style="background: #E2E2E2;" class="downClass">{{statusText}}</button>
        </div>
      </div>
   </div>
    <div v-else class="loadingBox">
      <van-loading type="spinner" color="#1989fa" />
      <div>加载中...</div>
    </div>
    <!-- 首次推广弹框 -->
    <van-overlay :show="isFirstTuiguang">
      <div class="first-tuiguang-wrapper" @click.stop>
        <div class="block-content">
          <div class="bg-image"></div>
          <div class="titile-tip">提示</div>
          <p class="tip-text">同一个培训项目同一个培训工种，不同技能等级仅可推广同一个资源供应商内容</p>
          <div class="btn-list">
            <div class="btn-text" @click="isFirstTuiguang = false">取消</div>
            <div class="btn-text-2" @click="confirmTuiguang">确定推广</div>
          </div>
        </div>
      </div>
    </van-overlay> 
     <!--推广链接弹框  -->
    <van-overlay :show="isShowLink">
      <div class="tuiguang-link-wrapper" @click.stop>
        <div class="block-content">
          <ul class="title-list">
            <li class="blank">1</li>
            <li class="title-text">推广</li>
            <li class="close" @click="closeIt"></li>
          </ul>
          <!-- 推广方式 -->
          <div class="ways-list"> 
            <div class="way-item">
              <div class="title">方法一：通过链接</div>
              <div class="desc">分享此链接到微信群和微信好友</div>
              <p class="link hide-text">{{detailObj.distributionUrl}}</p>
              <div class="btn-way" id="copyD" @click="copyUrl" 
              data-clipboard-action="copy"
              :data-clipboard-text="detailObj.distributionUrl"
              >复制链接</div>
            </div>
            <div class="way-item">
              <div class="title">方法二：通过二维码</div>
              <div class="erweima" id="qrcode" ref="qrcode" style="display:none"></div>
              <div class="erweima" >
                <img :src="imgSrc"/>
              </div>

              
              <div class="btn-way">长按保存</div>

            </div>

          </div>
        </div>
      </div>
    </van-overlay>
    <van-dialog v-model="formWdDialog" title="提示" overlay-class="wdformDialog" :showConfirmButton="false" cancelButtonText="关闭" confirm-button-color="#316FFF" show-cancel-button @confirm="enroll()" confirmButtonText="去报名">
      <p style="text-align:left">1、报名前，请联系机构了解线下实训场所<br/>2、客服电话：<a href="tel:400-8555-686">400-8555-686转1</a></p>
    </van-dialog>
 </div>
</template>

<script>
import share from '../../../utils/share';
import dayjs from 'dayjs'
import Clipboard from 'clipboard'
import QRCode from "qrcodejs2";
import courseItem from './courseItem.vue';
import { Toast } from 'vant';
import isRenShe from '../../../utils/judgeDomain'
import { U } from '../../../../public/static/pdfjs/build/pdf.worker';
import isrenshe from '@/utils/judgeDomain'
import { Dialog } from 'vant';

export default {
    name: "courseGoodsDetail",
    components: {
        courseItem
    },
    data () {
        return {
          formWdDialog:false,
          isrenshe: true,
          wdSubBranch:false,
          isHaveOffTrain:false,
          title:'商品详情',
          showT:false,
          goodsId:parseInt(this.$route.query.goodsId),//商品ID
          isData:false,
          detailObj:{
            basePrice: 0,//划线价格
            days: 0,//购买多少天有效
            desc: "",//简介
            endTime: "",
            goodsType: 1,//商品引用类型1.训练营 2.培训任务
            id: 0,
            isFree: 1,//是否免费
            isOrder: 0,//是否已经购买 0 否 1 是
            name: "",//商品名称
            picUrl: "",
            purchaseNo: 0,//报名人数
            referId: 0,//商品引用ID
            salePrice: 0,//销售价格
            startTime: "",
            status: 0,//状态 0下架 1上架 2 待上架
            taskType: 0,//任务类型 1.必修课 2.选修课 3.公开课
            tryWatch: 0,//商品有效期类型（1:永久有效、2:期间、3:固定时间、4:购买后期间）
            tryWatchList: [//可试看列表
              {
                itemId: 0,
                value: 0
              }
            ],
            validityType: 1//有效期类型
          },
          active:0,
          catalogList:[//目录列表
          ],
          isFirst:true,//是否一级
          isTab:'true',
          isJoinPer:'true',
          isShowNowSignUp: true,
          lastStudyCourseId:'',
          lastPlayItemId:'',
          taskId:'',
          canToQuicklyStudy:false,
          firstStudy:true,// 是否已经学过了
          isLogin: localStorage.getItem('token')?true:false,
          isRenshe: isRenShe ||false,
          isFirstTuiguang: false,
          isShowLink: false,
          imgSrc: ''
        };
    },
    computed: {
      //课程类型
      // type(){
      //   if(this.detailObj.taskType==1){
      //     return '必修课'
      //   }else if(this.detailObj.taskType==2){
      //     return '选修课'
      //   }else{
      //     return '公开课'
      //   }
      // },
        //上架状态
      statusText(){
        if(this.$route.query.goodsKind == 1){
            if(this.detailObj.status==1){
                  return '上架'
              }else if(this.detailObj.status==0){
                  return '下架'
              }else {
                  return '待上架'
              }
        }else {
              if(this.detailObj.status==1){
                  return '上架'
              }else if(this.detailObj.status==2){
                  return '下架'
              }else {
                   return '待上架'
              }
        }
      },
      
      //是否过期文案显示
      isOver(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>nowTime){
            return this.firstStudy ?'立即学习':'继续学习'
          }else{
            return '已过期'
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
            return this.firstStudy ?'立即学习':'继续学习'
          }else{
            if(startTime>nowTime){
              return '未开始'
            }else{
              return '已过期'
            }
          }
        }else{
          return this.firstStudy ?'立即学习':'继续学习'
        }
      },
      //是否过期
      isOverType(){
        if(this.detailObj.isOrder){
          if(this.detailObj.endTime&&this.detailObj.validityType==4){
            let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime();  
            let nowTime = new Date().getTime();
            if(lastTime>=nowTime){
              return 'oks'
            }else{
              return 'no2'
            }
          }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
            let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
            let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
            let nowTime = new Date().getTime();
            if(lastTime>=nowTime&&startTime<=nowTime){
              return 'oks'
            }else{
              if(startTime>=nowTime){
                return 'no1'
              }else{
                return 'no2'
              }
            }
          }else if(this.detailObj.validityType==1){
            return 'oks'
          }else{
            return 'ok'
          }
        }else{
            return 'ok'
        }
      },
      //有效期
      time(){
        if(this.detailObj.validityType==1){
          return "永久有效"
        }else if(this.detailObj.validityType==2){
          let sarr = this.detailObj.startTime.split(' ');
          let stimeArr = sarr[0].split('-');
          stimeArr[0] = stimeArr[0]+'年';
          stimeArr[1] = stimeArr[1]+'月';
          stimeArr[2] = stimeArr[2]+'日';
          let snewTime = stimeArr.join('');
          let earr = this.detailObj.endTime.split(' ');
          let etimeArr = earr[0].split('-');
          etimeArr[0] = etimeArr[0]+'年';
          etimeArr[1] = etimeArr[1]+'月';
          etimeArr[2] = etimeArr[2]+'日';
          let enewTime = etimeArr.join('');
          return snewTime+"-"+enewTime
        }else if(this.detailObj.validityType==3){
           if(this.detailObj.startTime&&this.detailObj.endTime){
              let sarr = this.detailObj.startTime.split(' ');
              let stimeArr = sarr[0].split('-');
              stimeArr[0] = stimeArr[0]+'年';
              stimeArr[1] = stimeArr[1]+'月';
              stimeArr[2] = stimeArr[2]+'日';
              let snewTime = stimeArr.join('');
              let earr = this.detailObj.endTime.split(' ');
              let etimeArr = earr[0].split('-');
              etimeArr[0] = etimeArr[0]+'年';
              etimeArr[1] = etimeArr[1]+'月';
              etimeArr[2] = etimeArr[2]+'日';
              let enewTime = etimeArr.join('');
              return snewTime+"-"+enewTime
           }else{
              let earr = this.detailObj.endTime.split(' ');
              let etimeArr = earr[0].split('-');
              etimeArr[0] = etimeArr[0]+'年';
              etimeArr[1] = etimeArr[1]+'月';
              etimeArr[2] = etimeArr[2]+'日';
              let enewTime = etimeArr.join('');
              return "至"+enewTime
           }
        }else{
          return "报名后"+this.detailObj.days+"天内有效"
        }
      },
    
    },
    created(){
      this.isrenshe = isrenshe;
      if(localStorage.getItem('token')){
        this.currBranchInfo();
      }
      sessionStorage.setItem('search',window.location.search)
      this.active = sessionStorage.getItem('theme') === 'minzheng'? 1:0
      let agentIdStorage = localStorage.getItem('detailAgentId')
      localStorage.setItem('detailAgentId', this.$route.query.agentId || agentIdStorage || '')
      if (this.$route.query.form && this.$route.query.form == 'promotionCenter' ) {
        console.log('隐藏立即报名')
        this.isShowNowSignUp = false
      }
      var frontendConfig = this.$store.state.dynamic.frontendConfig || [];
      var isTab = frontendConfig.filter((item)=>{
        return item.key == 'is_show_catalog_tab';
      })
      if(Array.isArray(isTab) && isTab.length === 0) {
        isTab = [{value:'true'}]
      }
      this.isTab = isTab[0] && isTab[0].value;

      var isJoinPer = frontendConfig.filter((item)=>{
        return item.key == 'is_show_commodity_number_buyer_detail_page';
      })
      this.isJoinPer = isJoinPer[0] && isJoinPer[0].value;
    },
    async mounted(){
      this.breadArr = localStorage.getItem('rehsheBread')?JSON.parse(localStorage.getItem('rehsheBread')):null
      
      if(this.$route.query.goodsKind == 2){
        await this.getDistributionGoodsInfo();// 分销商品详情
      }else {
        await this.getGoodsDetail();// 商品详情

      }
       this.getQuicklyStudyInfo();
       if (window.history && window.history.pushState) {
            // 往历史记录里面添加一条新的当前页面的url
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener("popstate", this.back, false); //false阻止默认事件
        }
        this.configObj = {
          desc:'家庭服务业（康养产业）职业技能培训服务平台',
          imgUrl:this.detailObj.picUrl,
          title:this.detailObj.name + `（${this.detailObj.skillLevel}）`,
          link:window.location.origin+`/goodsDetail?goodsKind=${this.$route.query.goodsKind}&goodsId=${this.goodsId}&fromType=${this.fromType}`
        }
        if(!this.isrenshe){
          share.getShare(this,this.configObj)
        }
    },
    destroyed(){
        window.removeEventListener("popstate", this.back, false); //false阻止默认事件
        // localStorage.setItem('detailAgentId', this.$route.query.agentId || '')
    },

    methods: {
      // 是否为伟东下的渠道方
      currBranchInfo(){
          this.$api.rensheUsercenter.currBranchInfo().then(res=>{
              if(res.data){
                  this.wdSubBranch = res.data.wdSubBranch;
              }
          })
      },
      getQuicklyStudyInfo(){
        if(this.detailObj.goodsType ==  3) return
        // 2培训任务 1训练营
        const taskType = this.detailObj.goodsType
        this.$api.learning.quicklyStudy(this.detailObj.referId,taskType).then( res => {
          if(res.code == 0 && res.data){
            this.canToQuicklyStudy = true
            this.lastStudyCourseId = res.data.courseId
            this.taskId = res.data.taskId
            this.lastPlayItemId = res.data.lastPlayItemId
            this.firstStudy = res.data.firstStudy
          }
        })
      },
      onChange(){
        if(document.getElementsByTagName('video').length>0){
          var video = document.getElementsByTagName('video');
          for(let i=0;i<video.length;i++){
            video[i].pause()
          }
        }
        if(document.getElementsByTagName('audio').length>0){
          var audio = document.getElementsByTagName('audio');
          for(let j=0;j<audio.length;j++){
            audio[0].pause()
          }
        }
      },
      //返回
      back(){
        if(this.breadArr) { // 如果存在
          // 
          this.$router.replace(this.breadArr[0])
        } else {
          if(this.isRenshe) { // 
            this.$router.replace('/home')
          } else {
            this.$router.replace('/distributionHomePage')

          }
        }
      },
      backHome() {
        if(this.isRenshe) { // 
            this.$router.replace('/home')
        } else {
          this.$router.replace('/distributionHomePage')

        }
      },
      // 目录大纲
      isToStudy(){
        if(this.detailObj.endTime&&this.detailObj.validityType==4){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime){
            this.toStudy()
          }
        }else if(this.detailObj.endTime&&(this.detailObj.validityType==2||this.detailObj.validityType==3)){
          let lastTime = new Date(this.detailObj.endTime.replace(/-/g,'/')).getTime(); 
          let startTime = new Date(this.detailObj.startTime.replace(/-/g,'/')).getTime(); 
          let nowTime = new Date().getTime();
          if(lastTime>=nowTime&&startTime<=nowTime){
              this.toStudy()
          }
        }else{
          this.toStudy()
        }
        
      },
      toStudy(){
        if(this.detailObj.goodsType==1){
          this.$router.push({
            path:'/learning/Details',
            query:{
              id:this.detailObj.referId,
              goodsId:this.goodsId,
              goodsKind:this.$route.query.goodsKind,
              distributionUserId:this.$route.query.distributionUserId,
              backPath:this.$route.path
            }
          })
        }else if(this.detailObj.goodsType==2){
          this.$router.push({
            path:'/learning/task/detail',
            query:{
              id:this.detailObj.referId,
              goodsId:this.goodsId,
              goodsKind:this.$route.query.goodsKind,
              distributionUserId:this.$route.query.distributionUserId,
              backPath:this.$route.path
            }
          })
        }else if(this.detailObj.goodsType==3){
          this.$router.push({
            path:'/humanResting/hrDetails',
            query:{
              id:this.detailObj.referId,//测评id
              refId:this.goodsId,
              refType:2,
              goodsId:this.goodsId,
              distributionUserId:this.$route.query.distributionUserId,
              goodsKind:this.$route.query.goodsKind,
              pathName:this.$route.path
            }
          })
        }
      },
      // 快速学习
      quicklyStudy(){
        localStorage.setItem("faceidentify", 0);
        this.$router.push({
          path: "/scourse",
          query: {
            courseId: this.lastStudyCourseId,
            trainId: this.taskId,
            taskType: this.detailObj.goodsType,
            backType:this.detailObj.goodsType == 1? 2:1  //1返回学习页面培训任务tab，2返回训练营tab
          }
        });
      },
      //获取商品基本信息  
      getGoodsDetail(){
        return this.$api.courseGoodsDetail.findGoodsDetailById(
          {
            goodsId: this.goodsId,
          }
        )
        .then(res=>{
            // console.log(res);
            if(res.code===0){
              this.detailObj = res.data;
              this.isData = true;
              this.getList();
            }
        })
      },
      /**
       * 分销商品详情
       */
      getDistributionGoodsInfo(){
          const params = {
              id:this.goodsId
          }
          return this.$api.courseGoodsDetail.getDistributionGoodsInfo({params}).then(res => {
              if(res.data){
                this.detailObj = res.data;
                this.isData = true;
                this.getList();
              }
          })
      },
      //目录数据查询
      getList() {
        // console.log(this.detailObj.goodsType)
        if(this.detailObj.goodsType==1){
          //训练营目录
          this.$api.courseGoodsDetail.findTrainListById(
            {
              id: this.detailObj.referId,
            }
          )
          .then(res=>{
              // console.log(res);
              if(res.code===0){
                let tempArr = [];
                  let endData = [];
                  let timeArr = [];
                  let num = [];
                  res.data.menuList.forEach(tiem => {
                      tiem.childItemList = [];
                      num.push(tiem.lastStudyTime);
                      if (tiem.lastStudyTime !== null) {
                          timeArr.push(tiem.lastStudyTime);
                      }
                  });
                  this.timelength = timeArr.sort(function(a, b) {
                      return b > a;
                  });
                  res.data.menuList.forEach(item => {
                      tempArr.push(item.stageName);
                  });
                  let setName = [...new Set(tempArr)]; //.sort();
                  setName.forEach((el, index) => {
                      let datalist = [];
                      res.data.menuList.forEach(items => {
                          if (el === items.stageName) {
                              items.stageName = items.label
                              datalist.push(items);
                          }
                      });
                      endData.push({
                          stageName: el,
                          id: index,
                          type:0,
                          childItemList: datalist,
                          sort:1
                      });
                  });
                  this.catalogList = endData;
                  console.log(this.catalogList)
              }else{
                console.log(res.data.message)
              }
          })
        }
        else if(this.detailObj.goodsType==2){
          //培训任务目录
          this.$api.courseGoodsDetail.findTaskListById(
            {
              id: this.detailObj.referId,
              version:1
            }
          )
          .then(res=>{
              // console.log(res);
              if(res.code==0){
                this.catalogList = res.data.taskItemVoList;
                //判断是否有线下实训
                this.catalogList.forEach((citem)=>{
                  if(citem.type==8){
                    this.isHaveOffTrain = true;
                  }
                })
              }
          })
        }

      },
      getQueryString(name) {
          /* eslint-disable */
        var search = window.location.search;
        //alert(search);
        var pattern = new RegExp("[?&]" + name + "\=([^&]+)", "g");
        var matcher = pattern.exec(search);
        var items = null;
        if (null != matcher) {
            try {
                items = decodeURIComponent(decodeURIComponent(matcher[1]));
            } catch (e) {
                try {
                    items = decodeURIComponent(matcher[1]);
                } catch (e) {
                    items = matcher[1];
                }
            }
        }
        return items;
        /* eslint-disable */
      },
      //立即报名
        enroll(type){
          if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
            if(new Date() - new Date(this.detailObj.signUpEndTime.replace(/-/g, "/")) > 0) {
              this.$toast({
                message: '报名已过期',

              })
              return 
            } else if((new Date() - new Date(this.detailObj.signUpStartTime.replace(/-/g, "/"))) < 0){
              this.$toast({
                message: '报名未开始',
              
              })
              return 
            }
          }
          if(type==1){
            this.formWdDialog = true
            return
          }
          if(localStorage.getItem('token')){
              if(this.detailObj.isFree){
                  localStorage.setItem('goodsOrderInfo','');
                  var data = {
                    activityGroupId:'',
                    totalMoney:type==2&&this.detailObj.shoppingGroup?this.detailObj.shoppingGroup.price:this.detailObj.secKillActivity&&this.detailObj.secKillActivity.curPeriod?this.detailObj.secKillActivity.price:this.detailObj.salePrice,
                    orderSource:2,
                    orderType:this.$route.query.goodsKind == 2 ? 4 : this.$route.query.goodsKind==1?localStorage.getItem('detailAgentId')?3:1:null,
                    agentId: localStorage.getItem('detailAgentId') || '',
                    distributionUserId:this.getQueryString('distributionUserId') || '',
                    shareUserInfo:sessionStorage.getItem('recommendUserUcode')?sessionStorage.getItem('recommendUserUcode'):'',
                    goodsList:[{
                        goodsId:this.goodsId,
                        goodsKinds:this.$route.query.goodsKind,
                        num:1,
                        promotionId:'',
                        salePrice:type==2&&this.detailObj.shoppingGroup?this.detailObj.shoppingGroup.price:this.detailObj.secKillActivity&&this.detailObj.secKillActivity.curPeriod?this.detailObj.secKillActivity.price:this.detailObj.salePrice
                    }],
                    realPay:type==2&&this.detailObj.shoppingGroup?this.detailObj.shoppingGroup.price:this.detailObj.secKillActivity&&this.detailObj.secKillActivity.curPeriod?this.detailObj.secKillActivity.price:this.detailObj.salePrice,
                    platformActivityRuleItemId:type==2&&this.detailObj.shoppingGroup?this.detailObj.shoppingGroup.activityRuleItemId:'',
                    platformSecKillActivityItemId:type==1&&this.detailObj.secKillActivity&&this.detailObj.secKillActivity.curPeriod?this.detailObj.secKillActivity.secKillActivityItemId:''
                  }
                  
                  this.$api.courseGoodsDetail.ordertoc({data}).then(res=>{
                    localStorage.setItem('goodsOrderInfo',JSON.stringify(res.data));
                    if(res.code==0){
                      if(res.data.placeOrderSuccess && res.data.realPayPrice > 0) {
                        this.$router.push({
                          path: "/gongxin_payment",
                          query: {
                              orderNo: res.data.orderNo,
                              agentId: localStorage.getItem('detailAgentId') || '',
                              iswx: 0,
                              isGroupGood:type==2?1:'',
                              goodsKinds: this.$route.query.goodsKind,
                          }
                        });
                      } else if(res.data.placeOrderSuccess && res.data.realPayPrice === 0){
                        this.showT = true;
                        setTimeout(() => {
                            this.showT = false;
                        }, 2000);
                        this.detailObj.isOrder = 1;
                      } else {
                        if(res.data.url) {
                          window.open(res.data.url, '_self')
                        } else {
                          this.$router.go(-1)
                        }
                      }
                    }else if(res.code==50001){
                      this.message = res.message;
                      this.showOrderTip = true;
                    }else{
                      Toast({
                        message: res.message,
                        duration: 2000
                      })
                    }
                  })
                }else{
                  this.$router.push({
                    path: "/gongxin_payment",
                    query: {
                      agentId:localStorage.getItem('detailAgentId') || '',
                      goodsId:this.$route.query.goodsId,
                      goodsKinds: this.$route.query.goodsKind,
                      isGroupGood:type==2?1:'',
                    }
                  });
                }
          }else{
              let agentId = localStorage.getItem('detailAgentId') && parseInt(localStorage.getItem('detailAgentId'))
              this.$router.push({
                path: "/login",
                // query: {name: "goodsDetail",agentId, ...this.$route.query}
                query: { 
                  name: "goodsDetail",
                  agentId:agentId || '', 
                  goodsId:parseInt(this.$route.query.goodsId),
                  goodsKind: this.$route.query.goodsKind,
                  // backName:this.$route.query.backName 
                }
              });
            }
      },
      formatDate(date) {
        return dayjs(date).format('YYYY.MM.DD') 
      },
      // 人社未登录时跳转伟东Toc
       async rensheWithoutLogin() {
        if(this.$route.query.branchId || localStorage.getItem('branchId')){
            this.$api.rensheUsercenter.getSaasBranchDomain(this.$route.query.branchId || localStorage.getItem('branchId')).then((res) => {
              if (res.success && res.data && res.data.length) {
                let h5domain = ''
                  res.data.forEach((item) => {
                    if (item.branchDomain) {
                      if (item.pcOrH5 === 1) {
                        h5domain = item.branchDomain;
                        let hostname = window.location.protocol + '//'+ h5domain;
                        window.open(`${hostname}/login?name=${`/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.$route.query.goodsKind}`, '_self')
                      } 
                    }
                  })
              }
            })
        }else{
          const hostname = window.location.hostname
          if((/ethrss-h5.wdeduc.com*/).test(window.location.hostname)){
            window.open('https://43515236.channel-h5.wdeduc.com/login', '_blank')
          }	else if((/jiafu-m.ethrss.cn*/).test(window.location.hostname)) {
            window.open('https://18188548.jiafu-m.ethrss.cn/login', '_blank')
          }	
					if(hostname.includes('ethrss') && !hostname.includes('jiafu') ){
            if(window.location.href.indexOf('testing3') > -1){
              window.open(`http://12639648.channel-h5.testing3.wdeduc.com/login?name=${`/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.$route.query.goodsKind}`, '_self')
              // window.open(`http://12639648.channel-h5.testing3.wdeduc.com/goodsDetail?goodsId=${this.goodsId}&goodsKind=${this.$route.query.goodsKind}`, '_self')

            }else if(window.location.href.indexOf('fat') > -1){
              window.open(`https://12655955.channel-h5.fat.wdeduc.com/login?name=${`/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.$route.query.goodsKind}`, '_self')
            }else if(window.location.href.indexOf('uat') > -1){
              window.open(`https://57796486.channel-h5.uat.wdeduc.com/login?name=${`/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.$route.query.goodsKind}`, '_self')
            } else{
              window.open(`https://43515236.channel-h5.wdeduc.com/login?name=${`/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.$route.query.goodsKind}`, '_self')
            }
          } else if(hostname.includes('mohrss')) {
            if (window.location.href.indexOf('testing3') > -1) {
							// window.open(
							// 	`http://12639648.channel.testing3.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
							// 	'_self'
							// )
						} else if (window.location.href.indexOf('fat') > -1) {
							// window.open(
							// 	`https://12655955.channel.fat.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
							// 	'_self'
							// )
						} else if (window.location.href.indexOf('uat') > -1) {
							window.open(
								`https://32156444.channel-h5.uat.wdeduc.com//login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
								'_self'
							)
						} else {
							// window.open(
							// 	`https://24868825.channel.wdeduc.com/login?name=${`/mall/goodsDetail`}&goodsId=${this.goodsId}&goodsKind=${this.goodsKind}`,
							// 	'_self'
							// )
						}
          }
        }
      },
      // 人社平台已登陆去推广
      async rensheLoginTuiguang() {
        if(this.detailObj.isDistribute === true) { // 已推广
        this.isShowLink = true
         setTimeout(()=> {
            this.qrcodeimg()
          }, 300)
          return 
        } else if(this.detailObj.isDistribute === false) { // 未推广
          let res = await this.$api.mall.canPromote({id: this.goodsId})
          
          if(res.code === 0){
            // let res = await this.$api.mall.getDistribute(
            //   {
            //     distributionGoodsId: this.detailObj.id,
            //   }
            // )
            if(res.code === 0) {

              await this.$api.mall.getDistribute({distributionGoodsId: this.detailObj.id})
              if(this.isHaveOffTrain){
                this.$toast("推广成功，登录渠道方管理后台商品管理添加实训计划");
              }else{
                this.$toast("推广成功");
              }
              await this.getDistributionGoodsInfo()
              this.isFirstTuiguang = false
              this.isShowLink = true
              setTimeout(()=> {
                this.qrcodeimg()
              }, 300)
            }
            
          }
         

        } else {
          return 
        }
      },
      async confirmTuiguang() {
        // 确定推广
        await this.getDistributionGoodsInfo()
        this.isFirstTuiguang = false
        this.isShowLink = true
        setTimeout(()=> {
          this.qrcodeimg()
        }, 300)
      },
      copyUrl(){
        const copyD = document.getElementById('copyD')
        // var url = this.detailObj.distributionUrl
        var clipboard = new Clipboard(copyD)
        // var clipboard = new Clipboard('.copy', {
        //   text: function () {
        //     return url
        //   }
        // })
        // 复制成功
        clipboard.on('success', () => {
          this.$toast('链接已复制')
          console.log('链接已复制')
          // 释放内存
          clipboard.destroy()
        })
        // 复制失败
        clipboard.on('error', () => {
            // 不支持复制
            this.$toast('复制失败')
            console.log('复制失败')
            // 释放内存
            clipboard.destroy()
        })
        console.log(clipboard, 'pppp')
      },
       //生成二维码
      qrcodeimg () {
        this.$refs.qrcode.innerHTML = ''
         var url = this.detailObj.distributionUrl
          setTimeout(() => {
              new QRCode(this.$refs.qrcode, {
                  text:url,
                  width: 110,
                  height: 110,
                  // colorDark: '#ffffff', // 二维码颜色
                  colorLight: '#ffffff', // 二维码背景色
                  correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
              }, 100)

              let myCanvas = document.getElementsByTagName('canvas')[0]
              var imgSrc = myCanvas.toDataURL("image/png")
              this.imgSrc = imgSrc
          })
      },
        //关闭推广弹框
      closeIt(){
        // this.$refs.qrcode = '';
        this.isShowLink = false
        this.imgSrc = ''
        setTimeout(() => {
          document.getElementById("qrcode").innerHTML = "";
        })
      },
        // 是否过期
      isGuoqi() {
         if(this.detailObj.signUpEndTime && this.detailObj.signUpStartTime) {
            if(new Date() - new Date(this.detailObj.signUpEndTime.replace(/-/g, "/")) > 0) {
              
              return true
            } else if((new Date() - new Date(this.detailObj.signUpStartTime.replace(/-/g, "/"))) < 0){
              
              return false
            }
        }
      }
    },
    beforeDestroy() {
      localStorage.removeItem('rehsheBread')
    }
}

</script>


<style lang="less" scoped>
 @import "../asset/css/courseGoodsDetail.less";
  /deep/.van-dialog__footer{
    width:100%;
  }
  /deep/.van-dialog__content{
    padding-bottom:12px;
    padding-top:12px;
    a{
      color:#316FFF;
    }
  }
 .detail-foot {
   button {
      width: 690px;
      height: 88px;
      text-align: center;
      line-height: 88px;
      background: linear-gradient(136deg, #407DF7 0%, #416DF6 100%);
      border-radius: 44px;
      border: 0;
      font-size: 32px;
      margin-top: 10px;
      color: #ffffff;
      &.leftBtn {
          width: 177px;
          height: 80px;
          line-height: 80px;
          background: #fff;
          border-radius: 44px;
          border: 1px solid #DDDDDD;
          margin-right: 18px;
          color: #666666;
      }
      &.rightBtn {
          // width: 495px;
          height: 88px;
          line-height: 88px;
          background: linear-gradient(136deg, #407DF7 0%, #416DF6 100%);
          border-radius: 44px;
          color: #FFFFFF;
      }
   }
 }
 .hide-text{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
 }
.hidden-single {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
 /deep/.van-nav-bar__content{
    .icon{
        font-size: 20px;
        color: #333;
    }
    .icon-home{
        margin-left: 10px;   
    }

}
/deep/.van-field__left-icon{
  color:#dbdbdb;
  font-size: 18px;
}

</style>
