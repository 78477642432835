<template>
    <div class="collapse">
        <!-- (detailObj.goodsType==2&&el.type!=1&&el.type!=2)||(detailObj.goodsType==1&&el.type!=0&&el.type!=1) -->
        <van-collapse :class="(!el.childItemList||el.childItemList.length==0?'collapse'+sort+' noAcro':(detailObj.goodsType==2&&el.type===2)||(detailObj.goodsType==1&&el.type===1)?'collapse'+sort+' Acro coursePad':'collapse'+sort+' Acro')" v-for="(el) in itemList" v-model="activeNames" :key="el.id">
            <van-collapse-item :name="el.id">
                <template #title>
                    <template v-if="detailObj.goodsType==2">
                        <template v-if="el.detailId">
                            <div class="inFourth" :key="el.id" @click="showPreviewPlayer(el)">
                                    <div class="inFourth-svg">
                                        <template v-if="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)">
                                            <img class="icon-img" v-if="el.courseType===1" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-v.png"/>
                                            <img class="icon-img" v-else-if="el.courseType===2" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-m.png"/>
                                            <img class="icon-img" v-else-if="el.courseType===3" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-w.png"/>
                                            <img class="icon-img" v-else-if="el.courseType===4" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-i.png"/>
                                            <svg-icon v-else-if="el.courseType===6" icon-class="scorm" class="lockStyle-good"></svg-icon>
                                            <img class="icon-img" v-else-if="el.courseType===10" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-l.png"/>
                                            <svg-icon v-else-if="el.courseType===11" icon-class="zhiboke" class="lockStyle-good"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===12" icon-class="shixunke" class="lockStyle-good"></svg-icon>
                                        </template>
                                        <template v-else>
                                            <svg-icon v-if="el.courseType===1" icon-class="course-video"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===2" icon-class="course-audio"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===3" icon-class="course-doc"></svg-icon>
                                            <img class="icon-img" v-else-if="el.courseType===4" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-i1.png"/>
                                            <svg-icon v-else-if="el.courseType===6" icon-class="scorm"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===10" icon-class="course-lianxi"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===11" icon-class="zhiboke"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===12" icon-class="shixunke"></svg-icon>
                                        </template>
                                        
                                        <!-- <span class="typeTip" v-if="ele.type===1">视频</span> -->
                                        <!-- <span class="typeTip" v-else-if="ele.type===2">音频</span>
                                        <span class="typeTip" v-else-if="ele.type===3">文档</span>
                                        <span class="typeTip" v-else-if="ele.type===4">图片</span>
                                        <span class="typeTip" v-else-if="ele.type===6">scorm</span>
                                        <span class="typeTip" v-else-if="ele.type===9">试卷</span>
                                        <span class="typeTip" v-else-if="ele.type===10">练习</span>
                                        <span class="typeTip" v-else-if="ele.type===11">直播</span>
                                        <span class="typeTip" v-else-if="ele.type===12">实训</span> -->
                                    </div>
                                    <div :class="isOverType!='oks'?'inFourth-title w75':'inFourth-title'">
                                        <span :class="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)?'title lock-text':'title'">{{el.contentName}}</span>
                                    </div>
                                    <div class="inFourth-btn" v-if="el.courseType!=1"> 
                                        <!-- v-if="isOverType!='oks'&&ele.tryWatch&&!detailObj.isFree" -->
                                        <span class="leftbtn" v-if="isOverType!='oks'&&el.tryWatch&&!detailObj.isFree"><svg-icon icon-class="course-live-replay"></svg-icon><i>试看</i></span>
                                        <!-- <span class="" v-else-if="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)"><img src="../../../assets/images/courseGoods/lock.png" class="lock"/></span> -->
                                    </div>
                                    
                                    <span class="" v-if="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)"><img src="../../../assets/images/courseGoods/lock.png" class="lock"/></span>
                            </div>
                                        <div v-if="el.courseType===1" class="time-lang" @click="showPreviewPlayer(el)">时长· {{formateTime(el.duration)}}
                                            <span class="leftbtn" v-if="isOverType!='oks'&&el.tryWatch&&!detailObj.isFree"><svg-icon icon-class="course-live-replay"></svg-icon><i>试看</i></span>
                                        </div>
                                        <div class="line" :style="{left:-(20*(num+1))/37.5+'rem'}"></div>
                        </template>
                        <template v-else>
                            <div v-if="el.type==1" class="left-line">
                                {{el.stageName?el.stageName:el.contentName}}
                                <div class="line"></div>
                            </div>
                            <div v-else class="notone" @click="toLearn(el)">
                                <p class="typeTip" v-if="el.type===2">课程</p>
                                <p class="typeTip" v-else-if="el.type===3">考试</p>
                                <p class="typeTip" v-else-if="el.type===4">直播</p>
                                <p class="typeTip" v-else-if="el.type===5">线上实训</p>
                                <p class="typeTip" v-else-if="el.type===6">表单</p>
                                <p class="typeTip" v-else-if="el.type===7">练习</p>
                                <p class="typeTip" :class="{flagTag:el.type===8}" v-else-if="el.type===8">线下实训</p>
                                <p class="names">{{el.stageName?el.stageName:el.contentName}}</p>
                                <div class="time-box" v-if="el.type===3">
                                  <template v-if="el.isLimitTime===1">
                                    {{formatTime(el.contentStartTime)+' ~ '+formatTime(el.contentEndTime)}}
                                  </template>
                                  <template v-else-if="el.isLimitTime===2">
                                    每月定期（{{el.regularDate}}）
                                  </template>
                                  <template v-else>
                                    长期有效
                                  </template>
                                </div>
                                <div class="time-box" v-if="el.type === 4">{{formatTime(el.contentStartTime)+' ~ '+formatTime(el.contentEndTime)}}</div>
                                <div class="time-box" v-if="el.type===6 && el.contentEndTime">结束时间:{{formatTime(el.contentEndTime)}}</div>
                                <div class="line" :style="el.type==null?{left:-(20*(num+1))/37.5+'rem'}:''"></div>
                            </div>
                        </template>
                    </template>
                    <template v-if="detailObj.goodsType==1">
                        <template v-if="el.detailId">
                            <div class="inFourth" :key="el.id" @click="showPreviewPlayer(el)">
                                    <div class="inFourth-svg">
                                        <template v-if="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)">
                                            <img class="icon-img" v-if="el.courseType===1" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-v.png"/>
                                            <img class="icon-img" v-else-if="el.courseType===2" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-m.png"/>
                                            <img class="icon-img" v-else-if="el.courseType===3" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-w.png"/>
                                            <img class="icon-img" v-else-if="el.courseType===4" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-i.png"/>
                                            <svg-icon v-else-if="el.courseType===6" icon-class="scorm" class="lockStyle-good"></svg-icon>
                                            <img class="icon-img" v-else-if="el.courseType===10" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-l.png"/>
                                            <svg-icon v-else-if="el.courseType===11" icon-class="zhiboke" class="lockStyle-good"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===12" icon-class="shixunke" class="lockStyle-good"></svg-icon>
                                        </template>
                                        <template v-else>
                                            <svg-icon v-if="el.courseType===1" icon-class="course-video"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===2" icon-class="course-audio"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===3" icon-class="course-doc"></svg-icon>
                                            <img class="icon-img" v-else-if="el.courseType===4" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-lock-i1.png"/>
                                            <svg-icon v-else-if="el.courseType===6" icon-class="scorm"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===10" icon-class="course-lianxi"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===11" icon-class="zhiboke"></svg-icon>
                                            <svg-icon v-else-if="el.courseType===12" icon-class="shixunke"></svg-icon>
                                        </template>
                                        <!-- <span class="typeTip" v-if="ele.type===1">视频</span> -->
                                        <!-- <span class="typeTip" v-else-if="ele.type===2">音频</span>
                                        <span class="typeTip" v-else-if="ele.type===3">文档</span>
                                        <span class="typeTip" v-else-if="ele.type===4">图片</span>
                                        <span class="typeTip" v-else-if="ele.type===6">scorm</span>
                                        <span class="typeTip" v-else-if="ele.type===9">试卷</span>
                                        <span class="typeTip" v-else-if="ele.type===10">练习</span>
                                        <span class="typeTip" v-else-if="ele.type===11">直播</span>
                                        <span class="typeTip" v-else-if="ele.type===12">实训</span> -->
                                    </div>
                                    <div :class="isOverType!='oks'?'inFourth-title w75':'inFourth-title'">
                                        <span class="title">{{el.contentName}}</span>
                                    </div>
                                    <div class="inFourth-btn" v-if="el.courseType!=1"> 
                                        <!-- v-if="isOverType!='oks'&&ele.tryWatch&&!detailObj.isFree" -->
                                        <span class="leftbtn" v-if="isOverType!='oks'&&el.tryWatch&&!detailObj.isFree"><svg-icon icon-class="course-live-replay"></svg-icon><i>试看</i></span>
                                    </div>
                                    <span class="" v-else-if="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)"><img src="../../../assets/images/courseGoods/lock.png" class="lock"/></span>
                            </div>
                                        <div v-if="el.courseType===1" class="time-lang">时长· {{formateTime(el.duration)}}
                                            <span class="leftbtn" v-if="isOverType!='oks'&&el.tryWatch&&!detailObj.isFree"><svg-icon icon-class="course-live-replay"></svg-icon><i>试看</i></span>
                                            <!-- <span class="" v-else-if="isOverType!='oks'&&(!el.tryWatch||detailObj.isFree)"><img src="../../../assets/images/courseGoods/lock.png" class="lock"/></span> -->
                                        </div>
                                        <div class="line" :style="{left:-(20*(num+1))/37.5+'rem'}"></div>
                        </template>
                        <template v-else>
                            <div v-if="el.type==0" class="left-line">
                                {{el.stageName?el.stageName:el.contentName}}
                                <div class="line"></div>
                            </div>
                            <div v-else class="notone">
                                <p class="typeTip" v-if="el.type===1">课程</p>
                                <p class="typeTip" v-else-if="el.type===2">考试</p>
                                <p class="names">{{el.stageName?el.stageName:el.contentName}}</p>
                                <div class="time-box" v-if="el.type===2">{{el.examStartTime&&el.examEndTime?formatTime(el.examStartTime)+' ~ '+formatTime(el.examEndTime):'长期有效'}}</div>
                                <div class="line" :style="el.type==null?{left:-(20*(num+1))/37.5+'rem'}:''"></div>
                            </div>
                        </template>
                    </template>
                    <div class="icon-container-goods" v-show="el.childItemList && el.childItemList.length && el.type!=8 || detailObj.goodsType==2 && isRenshe && el.type==8 && el.childItemList && el.childItemList.length>0">
                        <svg-icon icon-class="arrow-icon" class-name="arrow-icon"></svg-icon>
                    </div> 
                </template>
                <!-- 培训任务分销商品线下实训有实训基地信息时展示内容 -->
                <template v-if="detailObj.goodsType==2 && isRenshe && el.type==8 &&  el.childItemList && el.childItemList.length>0">
                    <div class="trainAddItem" v-for="(item2,index) in el.childItemList" :key="index" @click="toLearn(el)">
                        <p class="name">{{item2.trainingBaseName}}</p>
                        <p class="txt"><span class="title">地址：</span>{{item2.addressDetail}}</p>
                        <p class="txt"><span class="title">联系人：</span>{{item2.userName}}</p>
                        <p class="txt"><span class="title">联系电话：</span>{{item2.phone}}</p>
                    </div>
                </template>
                 <!-- 类似递归形式多级展示 内容-->
                 <template v-else>
                    <courseItem  v-if="el.childItemList && el.type!=8" :num="el.type==2&&sort!=1?1:el.type==null?num+1:0" :itemList="el.childItemList" :itemObj="el" :goodsId="goodsId" @loadRespurce="findResourceListById" :sort="sort+1" :isOverType="isOverType" :detailObj="detailObj"></courseItem>
                 </template>

                <!-- 课程类型显示数据资源 -->
                <!-- (detailObj.goodsType!=1&&el.type==2)||(detailObj.goodsType==1&&el.type==1) -->
                <!-- <ul v-if="(detailObj.goodsType!=1&&el.type==2)||(detailObj.goodsType==1&&el.type==1)">
                    <li class="inFourth" v-for="(ele) in resourceList[el.contentId]" :key="ele.id" @click="showPreviewPlayer(ele)">
                        <van-row type="flex" align="center">
                            <van-col span="2">
                                <svg-icon v-if="ele.type===1" icon-class="course-video"></svg-icon>
                                <svg-icon v-else-if="ele.type===2" icon-class="course-audio"></svg-icon>
                                <svg-icon v-else-if="ele.type===3" icon-class="course-doc"></svg-icon>
                                <svg-icon v-else-if="ele.type===4" icon-class="course-image"></svg-icon>
                                <svg-icon v-else-if="ele.type===6" icon-class="scorm"></svg-icon>
                                <svg-icon v-else-if="ele.type===10" icon-class="course-lianxi"></svg-icon>
                                <svg-icon v-else-if="ele.type===11" icon-class="zhiboke"></svg-icon>
                                <svg-icon v-else-if="ele.type===12" icon-class="shixunke"></svg-icon>
                            </van-col>
                            <van-col span="17">
                                <span class="title">{{ele.name}}</span>
                                <div v-if="ele.type===1" class="time-lang">时长· {{formateTime(ele.duration)}}</div>
                            </van-col>
                            <van-col span="5"> 
                                <span class="leftbtn" v-if="isOverType!='oks'&&ele.tryWatch&&!detailObj.isFree"><svg-icon icon-class="course-live-replay"></svg-icon>试放</span>
                                <span class="" v-else-if="isOverType!='oks'&&(!ele.tryWatch||detailObj.isFree)"><img src="../../../assets/images/courseGoods/lock.png" class="lock"/></span>
                            </van-col>
                        </van-row>
                    </li>
                </ul> -->
            </van-collapse-item>
        </van-collapse>
                <van-dialog class="tipIt" v-if="isShowD=='ok'&&isShowDs" v-model="show1" title="" :show-confirm-button="false">
                    <van-loading type="spinner" />
            <van-icon name="arrow-left" @click="back"/>
            <videoSaas ref="videoSaas2" @openIt="open" :idName="itemObj?itemObj.id:0" :isOrder="detailObj.isOrder"/>
        </van-dialog>
        <van-dialog class="tipIt2" v-if="isShowD=='ok'&&isShowDs" v-model="show2" title="" :show-confirm-button="false">
            <van-icon name="arrow-left" @click="back2"/>
            <div id="Vidpreview"></div>
        </van-dialog>
        <van-dialog class="tipIt3" v-if="isShowD=='ok'&&isShowDs" v-model="show3" title="" :show-confirm-button="false">
            <van-icon name="cross" @click="back3"/>
            <img :src="imgUrl" class="trySeeImg"/>
        </van-dialog>
        <van-dialog v-if="isShowD=='ok'&&isShowDs" v-model="show" title="试看结束" :show-confirm-button="false">
            <div class="overText" >报名后可查看完整内容</div>
            <div class="over-footer" >
                <button @click="cancel">取消</button>
                <button @click="toenroll">去报名</button>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import courseItem from "./courseItem.vue";
import { Toast, Dialog } from "vant";
import videoSaas from "./video.vue";
export default {
  name: "courseItem",
  components: {
    courseItem,
    videoSaas
  },
  props: {
    isRenshe: {
      type: Boolean,
      default: false
    },
    //层级列表
    itemList: {
      type: Array,
      default: () => []
    },
    //是否为训练营类型
    // isTrain: {
    //     type: Number,
    //     default: 2
    // },
    //是否为一级
    isFirst: {
      type: Boolean,
      default: false
    },
    num: {
      type: Number,
      default: 0
    },
    //是否免费
    // isFree:{
    //     type: Number,
    //     default: 0
    // },
    //可试看列表
    // tryWatchList:{
    //     type: Array,
    //     default: () => []
    // },
    //商品ID
    goodsId: {
      type: Number,
      default: 1
    },
    //商品引用ID
    // referId: {
    //     type: Number,
    //     default: 1
    // },
    //当前数据对象
    itemObj: {
      type: Object,
      default: () => {}
    },
    //层级数
    sort: {
      type: Number,
      default: 1
    },
    //是否报名
    // isOrder:{
    //     type: Number,
    //     default: 0
    // },
    //是否过期
    isOverType: {
      type: String,
      default: "no"
    },
    //商品信息
    detailObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false,
      activeNames: [0],
      resourceList: {}, //课程资源数据列表
      show1: false,
      show2: false,
      show3: false,
      imgUrl: "",
      goodsKind: null,
      isShowDs: false
    };
  },
  computed: {
    //每一层级是否显示视频图片文档的弹框
    isShowD() {
      var i = 0;
      this.itemList.forEach(el => {
        if (el.detailId && el.tryWatch) {
          i++;
        }
      });
      //  console.log(i)
      if (i > 0) {
        return "ok";
      } else {
        return "no";
      }
      //  var typeList = this.itemList.map(item => {
      //      return item.type
      //  })
      //  if(this.detailObj.goodsType==1){
      //      //训练营
      //      if(typeList.includes(1)){
      //          return 'ok'
      //      }else{
      //          return 'no'
      //      }
      //  }else if(this.detailObj.goodsType==2){
      //      //培训任务
      //      if(typeList.includes(2)){
      //          return 'ok'
      //      }else{
      //          return 'no'
      //      }
      //  }else{
      //      return 'no';
      //  }
    }
  },
  created() {
    //  console.log(this.isShowD)
    this.goodsKind = this.$route.query.goodsKind * 1;
    console.log(this.num);
  },
  watch: {
    //监控vuex中state的isOpen参数判断是返回跳转还是视频弹框关闭
    "$store.state.isOpen"(data) {
      this.show1 = data;
      if (!data && this.$refs.videoSaas2) {
        this.$refs.videoSaas2.dispose();
      }
    }
  },
  mounted() {
    if (this.itemList && this.itemList.length) {
      this.activeNames = [this.itemList[0].id];
    }
    //当this.itemObj.type=2时调用父组件获取课程资源列表findResourceListById方法
    //  if((this.detailObj.goodsType==2&&this.itemObj&&this.itemObj.type==2)||(this.detailObj.goodsType==1&&this.itemObj&&this.itemObj.type==1)){
    //  this.$emit('loadRespurce',this.itemObj.contentId)
    //  }
    this.findResourceListById();
  },
  methods: {
    //考试已经其他类型跳转任务大纲
    toLearn(row) {
      if (row.type != null && row.type != 2) {
        if (this.detailObj.isOrder && this.isOverType == "oks") {
          this.$router.push({
            path: "/learning/task/detail",
            query: {
              id: this.detailObj.referId,
              goodsId: this.goodsId,
              backPath: this.$route.path,
              nodeId: row.id,
              goodsKind:this.$route.query.goodsKind,
              distributionUserId:this.$route.query.distributionUserId
            }
          });
        } else {
          this.lock(1);
        }
      }
    },
    /** 秒换算时分秒 */
    formateTime(time) {
      const h = parseInt(time / 3600);
      const minute = parseInt((time / 60) % 60);
      const second = Math.ceil(time % 60);

      const hours = h < 10 ? "0" + h : h;
      const formatSecond = second > 59 ? 59 : second;
      return `${hours > 0 ? `${hours}:` : ""}${
        minute < 10 ? "0" + minute : minute
      }:${formatSecond < 10 ? "0" + formatSecond : formatSecond}`;
    },
    //时间格式转换
    formatTime(val) {
      if (val) {
        return (
          val
            .split(" ")[0]
            .split("-")
            .join(".") +
          " " +
          val
            .split(" ")[1]
            .split(":")
            .slice(0, 2)
            .join(":")
        );
      }
    },
    //关闭视频弹窗
    back() {
      this.$store.commit("setIsOpen", false);
      this.isShowDs = false;
      //清除视频
      //  if(this.$refs.videoSaas2){
      //     this.$refs.videoSaas2.dispose();
      //  }
    },
    //关闭文档弹窗
    back2() {
      this.show2 = false;
    },
    //关闭图片弹窗
    back3() {
      this.show3 = false;
      this.imgUrl = "";
    },
    //试看结束弹窗
    open() {
      this.show = true;
    },
    //点击试看
    showPreviewPlayer(row) {
      // if(localStorage.getItem('token')){ //
      //报名成功并且在有效期内时点击资源列表直接跳到对应培训任务或者训练营页面
      if (this.detailObj.isOrder && this.isOverType == "oks") {
        if (this.detailObj.goodsType == 1) {
          this.$router.push({
            path: "/learning/Details",
            query: {
              id: this.detailObj.referId,
              goodsId: this.goodsId,
              backPath: this.$route.path
            }
          });
        } else if (this.detailObj.goodsType == 2) {
          this.$router.push({
            path: "/learning/task/detail",
            query: {
              id: this.detailObj.referId,
              goodsId: this.goodsId,
              backPath: this.$route.path,
              nodeId: row.id
            }
          });
        } else if (this.detailObj.goodsType == 3) {
          this.$router.push({
            path: "/humanResting/hrDetails",
            query: {
              id: this.detailObj.referId, //测评id
              refId: this.goodsId,
              refType: 2,
              goodsId: this.goodsId,
              pathName: this.$route.path
            }
          });
        }
      } else {
        //试看列表存在可试看
        if (!this.detailObj.isFree && row.tryWatch) {
          var params = "";
          // if(row.transcodingStatus === 2) {
          this.isShowDs = true;
          if (row.courseType < 3) {
            this.show1 = true;
            this.$api.openTryWatch.getPlayAuthToken(row).then(res => {
              if (res.success && res.data) {
                // 音频
                if (row.courseType === 2) {
                  params = {
                    format: "mp3",
                    mediaType: "audio",
                    watchPercent: row.watchLength
                  };
                } else if (row.courseType === 1) {
                  params = {
                    format: "m3u8",
                    mediaType: "video",
                    watchPercent: row.watchLength
                  };
                }
                // console.log(params)
                // 视频，音频安全相关的
                // this.curVideoUrl = res.data;
                // this.dialogPreviewVisiblePlayer = true;
                // console.log(res.data,params)
                this.$nextTick(() => {
                  if(res.data?.sourceSing && (res.data?.sourceSing=='222' || res.data.sourceSing=='333')){
                    // 三方，222 格莱森的，333交通的
                    this.$refs.videoSaas2.initPlayer(res.data.playUrl,true,{watchPercent: row.tryWatchLength,duration:row.duration})
                  }else{
                    this.$refs.videoSaas2.initPlayer(res.data,false, params)
                  }
                });
                // this.show1=true;
                this.$store.commit("setIsOpen", true);
                // console.log(this.$store.state.isOpen)
              }
            });
          } else if (row.courseType === 4) {
            this.$api.openTryWatch.getImageOrDocUrl(row).then(res => {
              if (res.data) {
                this.imgUrl = res.data;
                this.show3 = true;
              }
            });
          } else if (row.courseType === 3) {
            this.$api.openTryWatch.getDocumentAccessToken(row).then(res => {
              if (res.data) {
                // 下边是安全才有的
                this.show2 = true;
                setTimeout(() => {
                  // eslint-disable-next-line no-undef
                  let demo = aliyun.config({
                    mount: document.querySelector("#Vidpreview"),
                    url: res.data.previewURL //设置文档预览URL地址。
                  });
                  // console.log(document.querySelector('#Vidpreview'))
                  // console.log('demo',demo)
                  // //设置AccessToken。
                  demo.setToken({ token: res.data.accessToken });
                  // ppt存在动画才有，否则会报错
                  if (row.name.split(".")[1] === "ppt") {
                    demo
                      .PPTApplication()
                      .ActivePresentation.SlideShowSettings.Run();
                    demo.PPTApplication().ActivePresentation.Slides.Count;
                  }
                }, 100);
              }
            });
          } else if (
            row.courseType === 6 ||
            row.courseType === 9 ||
            row.courseType === 10 ||
            row.courseType === 11 ||
            row.courseType === 12
          ) {
            // scrom预览
            // this.$router.push({
            //     path: '/resources/scrom/myScrom',
            //     query: {
            //         resId:row.id,
            //         version:row.sourceSign,
            //     },
            // });
            Toast("请点击立即学习进入");
          }
        } else {
          this.lock();
        }
      }
      // } else {
      //     this.$message.warning('转码成功方可预览');
      // }
      // } else { //
      //     let agentId = localStorage.getItem('detailAgentId') && parseInt(localStorage.getItem('detailAgentId'))
      //     this.$router.push({
      //         path: "/login",
      //         query: {name: "goodsDetail",agentId, ...this.$route.query}
      //         // query: {
      //         //   name: "goodsDetail",
      //         //   agentId:agentId || '',
      //         //   goodsId:parseInt(this.$route.query.goodsId),
      //         //   goodsKind: this.$route.query.goodsKind,
      //         //   backName:this.$route.query.backName
      //         // }
      //     });
      // }
    },
    getQueryString(name) {
      /* eslint-disable */
      var search = window.location.search;
      //alert(search);
      var pattern = new RegExp("[?&]" + name + "=([^&]+)", "g");
      var matcher = pattern.exec(search);
      var items = null;
      if (null != matcher) {
        try {
          items = decodeURIComponent(decodeURIComponent(matcher[1]));
        } catch (e) {
          try {
            items = decodeURIComponent(matcher[1]);
          } catch (e) {
            items = matcher[1];
          }
        }
      }
      return items;
      /* eslint-disable */
    },
    //试看结束弹框去报名
    toenroll() {
      if (localStorage.getItem("token")) {
        var data = {
          goodsId: this.goodsId,
          orderSource: 2,
          orderType: this.$route.query.goodsKind == 2 ? 4 : null,
          agentId: localStorage.getItem("detailAgentId") || "",
          distributionUserId: this.getQueryString("distributionUserId") || ""
        };
        this.$api.courseGoodsDetail.order({ data }).then(res => {
          // if(this.detailObj.isFree==1){
          //     Toast({
          //         message: '报名成功',
          //         icon: 'passed',
          //     });
          //     setTimeout(()=>{
          //         this.$router.push({
          //             path: "/learning/Details",
          //             query: {
          //                 id: this.detailObj.referId
          //             }
          //         });
          //     },2000)
          // } else {
          //     this.$router.push({
          //         path: "/gongxin_payment",
          //         query: {
          //             orderNo: res.data.orderNo,
          //             agentId: localStorage.getItem('detailAgentId') || '',
          //             iswx: 0
          //         }
          //     });
          // }
          if (res.code == 0) {
            this.show = false;
            if (res.data.placeOrderSuccess && res.data.realPayPrice > 0) {
              this.$router.push({
                path: "/gongxin_payment",
                query: {
                  orderNo: res.data.orderNo,
                  agentId: localStorage.getItem("detailAgentId") || "",
                  iswx: 0
                }
              });
            } else if (
              res.data.placeOrderSuccess &&
              res.data.realPayPrice == 0
            ) {
              //
              this.$message({
                message: "报名成功",
                type: "success"
              });
              // this.showT = true;
              // setTimeout(() => {
              //     this.showT = false;
              // }, 2000);
              this.detailObj.isOrder = 1;
              setTimeout(() => {
                this.$router.push({
                  path: "/learning/Details",
                  query: {
                    id: this.detailObj.referId
                  }
                });
              }, 2000);
            } else {
              if (res.data.url) {
                window.open(res.data.url, "_self");
              } else {
                this.$router.go(-1);
              }
            }
          }
        });
      } else {
        this.$router.push({
          path: "/login",
          query: { name: "goodsDetail", ...this.$route.query }
        });
      }
    },
    //关闭试看结束弹框
    cancel() {
      this.show = false;
      this.back();
    },
    //不可试看提示
    lock(type) {
      if (this.$route.query.goodsKind == 2) {
        // 分销商品
        return false;
      } else {
        if (this.isOverType == "no1") {
          Toast("课程未开始！");
        } else if (this.isOverType == "no2") {
          Toast("课程已过期！");
        } else {
          if (type) {
            Toast("报名后才可以学习！");
          } else {
            Toast("报名后才可以观看！");
          }
        }
      }
    },
    //查询课程资源数据列表
    findResourceListById(id) {
      // if(this.detailObj.goodsType==1){
      //     this.$api.courseGoodsDetail.findResourceListById(
      //         {
      //             id: id,
      //         }
      //     )
      //     .then(res=>{
      //         // console.log(res);
      //         if(res.code==0){
      //             var arr = res.data;
      //             //试看列表
      //             if(this.detailObj && this.detailObj.tryWatchList){
      //                 var newArray = this.detailObj.tryWatchList.map((item) => {
      //                     return item.itemId;
      //                 })
      //                 // console.log(newArray)
      //                 //匹配试看信息
      //                 arr.forEach(el => {
      //                     if(newArray.indexOf(el.id)>-1){
      //                         el.watchLength = this.detailObj.tryWatchList.find((v) => {
      //                             return v.itemId == el.id;
      //                         }).value;
      //                         el.tryWatch = 1;
      //                     }else{
      //                         el.tryWatch = 0;
      //                     }
      //                 });
      //             }

      //             this.$set(this.resourceList, id, arr)
      //             // console.log(this.resourceList)
      //         }else{
      //             console.log(res)
      //         }
      //     })
      // }else{
      var newArray = this.detailObj.tryWatchList.map(item => {
        return item.itemId;
      });
      this.itemList.forEach(el => {
        if (el.detailId) {
          if (newArray.indexOf(el.id) > -1) {
            this.$set(
              el,
              "watchLength",
              this.detailObj.tryWatchList.find(v => {
                return v.itemId == el.id;
              }).value
            );
            this.$set(el, "tryWatch", 1);
            // el.watchLength = this.detailObj.tryWatchList.find((v) => {
            //     return v.itemId == el.id;
            // }).value;
            // el.tryWatch = 1;
          } else {
            this.$set(el, "tryWatch", 0);
            // el.tryWatch = 0;
          }
        }
      });
      // console.log(this.itemList)
      // }
    }
  }
};
</script>
<style lang="css" scoped>
@import "../asset/css/courseItem.css";
</style>